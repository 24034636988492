import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type PageState = {
  searchString: string;
};

const initialState: PageState = { searchString: '' };

export const pageStateSlice = createSlice({
  name: 'pageState',
  initialState,
  reducers: {
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
  },
});

export const { setSearchString } = pageStateSlice.actions;
