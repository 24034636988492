import urlConfig, { UrlConfig } from './url.config';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SERVER_ENVIRONMENTS {
  local = 'local',
  dev = 'dev',
  prd = 'prd',
}

const SERVER_ENV: SERVER_ENVIRONMENTS =
  SERVER_ENVIRONMENTS[process.env.NEXT_PUBLIC_SERVER_ENVIRONMENT as SERVER_ENVIRONMENTS] || SERVER_ENVIRONMENTS.local;

type Environment = {
  constants: {
    searchDelay: number;
  };
  devTool: boolean;
  environment: SERVER_ENVIRONMENTS;
};

const defaultEnv: Environment = {
  devTool: true,
  environment: SERVER_ENVIRONMENTS.local,
  constants: {
    searchDelay: 500,
  },
};

const configs: {
  [K in SERVER_ENVIRONMENTS]: Partial<Environment>;
} = {
  local: {},
  prd: {
    devTool: false,
  },
  dev: {},
};

export const envConfig = {
  ...defaultEnv,
  ...configs[SERVER_ENV],
  environment: SERVER_ENV,
  url: (urlConfig as UrlConfig)[SERVER_ENV],
} as const;
