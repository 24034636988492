import localFont from '@next/font/local';

const firsNeue = localFont({
  src: [
    { path: './tt_firs_neue_regular-webfont.woff', weight: '400', style: 'normal' },
    { path: './tt_firs_neue_demibold-webfont.woff', weight: '600', style: 'normal' },
    { path: './tt_firs_neue_regular-webfont.woff2', weight: '400', style: 'normal' },
    { path: './tt_firs_neue_demibold-webfont.woff2', weight: '600', style: 'normal' },
  ],
});

export default firsNeue;
