import '../styles/globals.css';
import type { AppProps } from 'next/app';
import App from 'next/app';
import { AppContext } from 'next/dist/pages/_app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { Toaster } from 'react-hot-toast';
import { Provider as ReduxProvider } from 'react-redux';
import firsNeue from 'src/fonts/firsNeue';
import nextI18NextConfig from '../../next-i18next.config';
import { ReactQueryProvider } from '../services/reactQuery';
import { storeWrapper } from '../services/store/store';
import { NextPageWithLayout } from '../utils/types/NextPageWithLayout';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, ...rest }: AppPropsWithLayout): JSX.Element {
  const getLayout = Component.getLayout ?? (page => page);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { store, props } = storeWrapper.useWrappedStore(rest);

  return (
    <div className={firsNeue.className}>
      <ReduxProvider store={store}>
        <ReactQueryProvider withSSR={false} /* dehydratedState={props.pageProps.dehydratedState} */>
          <Head>
            <meta charSet="UTF-8" />
            <title>Lingua</title>
            {/* <meta name="description" content={t('head.defaultDescription')} /> */}
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </Head>
          {getLayout(
            <>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Component {...props} />
            </>,
          )}
          <Toaster position="bottom-right" />
        </ReactQueryProvider>
      </ReduxProvider>
    </div>
  );
}

const MyAppWithErrorCatcher = ({ Component, ...pageProps }: AppPropsWithLayout): JSX.Element => (
  /* @ts-expect-error MyApp is missing some properties apparently, I don't see why */
  <MyApp Component={Component} pageProps={pageProps} />
);

MyAppWithErrorCatcher.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { err } = appContext.ctx;
  if (!process.browser && err) {
    // eslint-disable-next-line no-console
    console.error('SSR error : ', err);
  }
  return { ...appProps };
};

export default appWithTranslation(MyAppWithErrorCatcher, nextI18NextConfig);
