// @ts-check

/** @type { import('./url.config').SingleUrlConfig } */
const defaultUrlConfig = {
  api: {
    clientSide: 'http://localhost:8080',
    serverSide: 'http://localhost:8080',
  },
  base: 'http://localhost:8080',
};

/** @type { import("./url.config").UrlConfig }  */
const urlConfig = {
  local: defaultUrlConfig,
  dev: {
    api: {
      clientSide: 'https://lingua-dev.appti.ch',
      serverSide: 'https://lingua-dev.appti.ch',
    },
    base: 'https://lingua-dev.appti.ch',
  },
  prd: {
    api: {
      clientSide: 'https://app.linguine.dev',
      serverSide: 'https://app.linguine.dev',
    },
    base: 'https://app.linguine.dev',
  },
};

module.exports = urlConfig;
