import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { pageStateSlice } from './slices/pageStateSlice';
import { envConfig } from '../../../envConfig';

const makeStore = () =>
  configureStore({
    devTools: envConfig.devTool,
    reducer: { [pageStateSlice.name]: pageStateSlice.reducer },
  });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export const storeWrapper = createWrapper<AppStore>(makeStore);
