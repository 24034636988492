import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError } from 'axios';
import { ReactNode, useState } from 'react';
import { envConfig } from '../../envConfig';

interface ReactQueryProviderPropsCommon {
  children: ReactNode;
}
interface ReactQueryProviderPropsWithSSR extends ReactQueryProviderPropsCommon {
  dehydratedState: unknown;
  withSSR: true;
}
interface ReactQueryProviderPropsWithoutSSR extends ReactQueryProviderPropsCommon {
  withSSR: false;
}
type ReactQueryProviderProps = ReactQueryProviderPropsWithoutSSR | ReactQueryProviderPropsWithSSR;

export const ReactQueryProvider = ({ children, withSSR, ...props }: ReactQueryProviderProps): JSX.Element => {
  const dehydratedState = 'dehydratedState' in props ? props.dehydratedState : null;
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: true,
            retry: (numRetries, error) =>
              !(error instanceof AxiosError && error?.response?.status === 404) && numRetries < 3,
            retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
          },
        },
      }),
  );

  return (
    <QueryClientProvider client={queryClient}>
      {withSSR && <Hydrate state={dehydratedState}>{children}</Hydrate>}
      {!withSSR && children}
      {envConfig.devTool && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};
